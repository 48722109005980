import _ from 'lodash';
import moment from 'moment';

// 物件標準化，替換 keyName
// 活動
const eventTable = {
  startTime: 'startDate',
  date: 'startDate',
  endTime: 'endDate',
  activityNameZh: 'title',
  activityFormOther: 'eventType',
  otherActivityType: 'eventType',
  isConfirm: 'resultStatus',
  isSec1Type: 'firstStatus',
  isSec2Type: 'secondStatus',
  number: 'numNo',
  picture: 'coverImage',
  url: 'eventLink',
};

// 案件(表單)
const eventFormTable = {
  concert: {
    isConfirm: 'status',
    activityNameZh: 'activityName',
    activityNameEn: 'activityNameEN',
    activityForm: 'activitySessionType',
    area: 'activityArea',
    ticketing: 'activityCharge',
    startTime: 'totalStartDate1',
    endTime: 'totalEndDate1',
    showStartTime: 'showStartDate1',
    showEndTime: 'showEndDate1',
    startTime1: 'totalStartDate2',
    endTime1: 'totalEndDate2',
    showStartTime1: 'showStartDate2',
    showEndTime1: 'showEndDate2',
    fileName: 'file1',
    fileName1: 'file2',
    fileName2: 'file3',
    actContactName: 'contactName',
    actContactEmail: 'contactEmail',
    actContactTel: 'contactTel',
    actContactMobile: 'contactMobile',
    actContactAddress: 'address',
  },
  cultural: {
    number: 'numNo',
    accountId: 'userId',
    activityNameZh: 'activityName',
    activityNameEn: 'activityNameEN',
    proSource: 'activitySource',
    otherActivityType: 'activityTypeOther',
    rentRange: 'activityArea',
    otherRentRange: 'activityAreaOther',
    ticketing: 'activityCharge',
    personCount: 'activityPeople',
    startTime: 'totalStartDate1',
    endTime: 'totalEndDate1',
    typeStartTime: 'showStartDate1',
    typeEndTime: 'showEndDate1',
    typeCount1: 'showDayCount1',
    startTime1: 'totalStartDate2',
    endTime1: 'totalEndDate2',
    typeStartTime1: 'showStartDate2',
    typeEndTime1: 'showEndDate2',
    typeCount2: 'showDayCount2',
    fileName: 'file1',
    fileName1: 'file2',
    contactAddress: 'address',
  },
};

// datetime => date
// 2006-10-27T00:00:00 => 2006-10-27
const dateParser = (obj) => _.mapValues(obj, (value, key) => {
  if (key === 'createTime') return value; // createTime 不轉換
  if (_.isString(value) && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
    return value.split('T')[0];
  }
  return value;
});

// 雙向轉換
// "1,2" <=> ["1", "2"]
const timeOptionsConverter = (obj, isToString) => {
  const newObj = _.cloneDeep(obj);
  function converter(list) {
    return _.map(list, (item) => {
      let { timeDuration } = item;
      if (isToString) {
        timeDuration = _.isEmpty(timeDuration) ? '' : timeDuration.join(',');
      } else {
        timeDuration = _.isEmpty(timeDuration) ? [] : timeDuration.split(',');
      }
      return {
        ...item,
        timeDuration,
      };
    });
  }

  newObj.entryExitsList1 = converter(newObj.entryExitsList1);
  newObj.entryExitsList2 = converter(newObj.entryExitsList2);
  return newObj;
};

// 依據 entryExitsList + showStartDate 產生 entryList + exitsList
const entryExitConverter = (obj, isCombine) => {
  let newObj = _.cloneDeep(obj);

  // 依 showStartDate 做分組
  const groupByShowDate = (entryExitsList, startDate) => {
    const emptyObj = { exitsList: [], entryList: [] };
    // 空值
    if (_.isEmpty(entryExitsList) || _.isEmpty(startDate)) return emptyObj;

    const whichList = ({ entryExitDate }) => (moment(entryExitDate).isSameOrBefore(startDate) ? 'entryList' : 'exitsList');
    const groupedObj = _.groupBy(entryExitsList, whichList);
    return _.merge(emptyObj, groupedObj);
  };

  if (isCombine) {
    // 合併成 entryExitsList
    newObj.entryExitsList1 = [...newObj.entryList1, ...newObj.exitsList1];
    newObj.entryExitsList2 = [...newObj.entryList2, ...newObj.exitsList2];
    newObj = _.omit(newObj, ['entryList1', 'exitsList1', 'entryList2', 'exitsList2']);
    // 合併後將陣列轉字串
    newObj = timeOptionsConverter(newObj, true);
  } else {
    // 拆解成 entryList + exitsList
    const first = groupByShowDate(newObj.entryExitsList1, newObj.showStartDate1);
    const second = groupByShowDate(newObj.entryExitsList2, newObj.showStartDate2);

    newObj.entryList1 = first.entryList;
    newObj.exitsList1 = first.exitsList;
    newObj.entryList2 = second.entryList;
    newObj.exitsList2 = second.exitsList;
  }
  return newObj;
};

// isInvert: 反轉(資料送後端時)
const eventsFormatter = (events, isInvert = false) => {
  const table = isInvert ? _.invert(eventTable) : eventTable;
  const replaceKeyName = (val, key) => (table[key] || key);
  return _.map(
    events,
    (event) => _.mapKeys(event, replaceKeyName),
  );
};

const eventFormFormatter = (eventForm, siteId, isInvert = false) => {
  let formattedEventForm = eventForm;
  const siteName = siteId === 1 ? 'concert' : 'cultural';

  let table = eventFormTable[siteName];
  table = isInvert ? _.invert(table) : table;
  formattedEventForm = _.mapKeys(
    formattedEventForm,
    (val, key) => (table[key] || key),
  );

  // 去除空值
  formattedEventForm = _.omitBy(formattedEventForm, _.isNull);
  // datetime 改成 date
  formattedEventForm = dateParser(formattedEventForm);

  // 表演廳額外轉換
  if (siteId === 1) {
    // 進撤場時段，欄位格式轉換 "1,2" <=> ["1", "2"]
    formattedEventForm = timeOptionsConverter(formattedEventForm, isInvert);
    // 合併/拆解 進撤場日陣列
    formattedEventForm = entryExitConverter(formattedEventForm, isInvert);
  }
  return formattedEventForm;
};

const objectFormatter = { eventsFormatter, eventFormFormatter };
export default objectFormatter;
