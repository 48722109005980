import { defineStore } from 'pinia';
import _ from 'lodash';
import api from '@/api/api';
import { useLoadingScreen } from './index';
import { useUserData } from './user';
import { culturalFormula, concertFormula } from '../utils/estimateFormula';

export const useFormStore = defineStore('formStore', {
  state: () => ({
    siteId: 1,
    formStep: 0,
    formData: {},
    formOption: {
      concert: null,
      cultural: null,
    },
    concertTimeOption: null,
    rentRateTable: {},
  }),
  getters: {
    isConcert() {
      return this.siteId === 1;
    },
    isDraft() {
      // 判斷當前表單是否為草稿
      return _.has(this.formData, 'status');
    },
    // 當前試算
    rentEstimate() {
      if (!this.rentRateTable) return null;
      if (this.isConcert) {
        return [
          concertFormula({
            totalStartDate: this.formData.totalStartDate1,
            totalEndDate: this.formData.totalEndDate1,
            showStartDate: this.formData.showStartDate1,
            showEndDate: this.formData.showEndDate1,
            sessionList: this.formData.sessionList1,
            entryList: this.formData.entryList1,
            exitsList: this.formData.exitsList1,
            activityCharge: this.formData.activityCharge,
            feeTable: this.rentRateTable,
          }),
          concertFormula({
            totalStartDate: this.formData.totalStartDate2,
            totalEndDate: this.formData.totalEndDate2,
            showStartDate: this.formData.showStartDate2,
            showEndDate: this.formData.showEndDate2,
            sessionList: this.formData.sessionList2,
            entryList: this.formData.entryList2,
            exitsList: this.formData.exitsList2,
            activityCharge: this.formData.activityCharge,
            feeTable: this.rentRateTable,
          }),
        ];
      }
      return [
        culturalFormula({
          totalStartDate: this.formData.totalStartDate1,
          totalEndDate: this.formData.totalEndDate1,
          showStartDate: this.formData.showStartDate1,
          showEndDate: this.formData.showEndDate1,
          feeTable: this.rentRateTable,
        }),
        culturalFormula({
          totalStartDate: this.formData.totalStartDate2,
          totalEndDate: this.formData.totalEndDate2,
          showStartDate: this.formData.showStartDate2,
          showEndDate: this.formData.showEndDate2,
          feeTable: this.rentRateTable,
        }),
      ];
    },
    filesField() {
      return this.isConcert ? ['file1', 'file2', 'file3'] : ['file1', 'file2'];
    },
    submitFormData() {
      return _.omit(this.formData, ['acceptTerm', ...this.filesField]);
    },
    submitFiles() {
      return _.pick(this.formData, this.filesField);
    },
  },
  actions: {
    async submitEventForm(isSaveAsDraft = false) {
      const userData = useUserData();
      const loading = useLoadingScreen();

      const saveStatus = isSaveAsDraft ? 4 : 2;
      await this.fetchRentRateTable();

      const apiCollection = this.isConcert ? 'userConcertEvent' : 'userCulturalEvent';
      // const apiMethod = this.isDraft ? 'update' : 'create';

      function nullResult(message) {
        loading.setLoading(false);
        return { submitResult: null, fileResult: null, resultMessage: message };
      }

      const hasEmptyDate = (targetListName, propertyName) => (
        _.chain(this.submitFormData)
          .pick(targetListName)
          .values()
          .flatten()
          .tap((x) => { console.log(x); })
          .some((item) => !item[propertyName] || item[propertyName] === '')
          .value()
      );

      loading.setLoading(true);

      if (isSaveAsDraft) {
        // 草稿至少填寫活動名稱
        if (_.isEmpty(this.submitFormData.activityName)) return nullResult('儲存草稿時，活動名稱為必填');
        // 有任何日期欄位留空值
        if (
          hasEmptyDate(['entryList1', 'exitsList1', 'entryList2', 'exitsList2'], 'entryExitDate')
          || hasEmptyDate(['sessionList1', 'sessionList2'], 'showDate')
        ) {
          return nullResult('儲存草稿時，進撤場或表演日的日期欄位不得留空');
        }
      } else {
        // 檢查是否為可申請的時段(允許儲存成草稿)
        const configs = [
          {
            fieldName: '第一意願',
            startDate: this.submitFormData.totalStartDate1,
            endDate: this.submitFormData.totalEndDate1,
            siteId: this.siteId,
            activitySessionType: this.submitFormData.activitySessionType,
          },
          {
            fieldName: '第二意願',
            startDate: this.submitFormData.totalStartDate2,
            endDate: this.submitFormData.totalEndDate2,
            siteId: this.siteId,
            activitySessionType: this.submitFormData.activitySessionType,
          },
        ];

        const resArray = await Promise.all(_.chain(configs)
          .filter(({ startDate, endDate }) => !!startDate && !!endDate)
          .map(api.rentalDurationValidate)
          .value());
        const InvalidArray = _.filter(resArray, (resItem) => resItem.isValid !== true); // 排除 undefined(沒填寫) 和 false(非申請時段)
        if (InvalidArray.length > 0) {
          const str = _.map(InvalidArray, 'fieldName').join('、');
          return nullResult(`您選擇的"${str}"檔期目前不開放申請，您可以先儲存成草稿或更換檔期`);
        }
      }

      let createResult = {};

      // 先建立草稿避免中斷
      if (this.isDraft) {
        createResult.id = this.formData.id;
      } else {
        createResult = await api[apiCollection].create({
          ...this.submitFormData,
          status: 4,
          cMId: this.formData.cMId,
        }, true);
        // 把資料同步到 formData
        this.formData = _.assign({}, this.formData, { status: 4, id: createResult.id });
      }

      // 上傳檔案
      const fileResult = await api.uploadFilesToRent(this.submitFiles, {
        id: createResult.id,
        uploadType: this.siteId,
        Authorization: `Bearer ${userData.token}`,
      });

      // 上傳檔案錯誤檢查
      const invalidResult = _.filter(fileResult, (resultItem) => resultItem.success === false);
      if (invalidResult.length > 0) {
        const message = _.map(invalidResult, (resultItem) => `${resultItem.fieldName}: ${resultItem.message}`);
        return nullResult(`${message.join('\n')} \n 案件已自動儲存成草稿，您可以現在或稍後補上傳附件`);
      }

      // 實際送出或存成草稿
      const submitResult = await api[apiCollection].update({
        ...this.submitFormData,
        status: saveStatus,
        cMId: this.formData.cMId,
      }, isSaveAsDraft);

      loading.setLoading(false);
      return { submitResult, fileResult };
    },
    async loadDraft(siteId, id) {
      const loading = useLoadingScreen();
      const apiMethod = siteId === 1 ? 'userConcertEvent' : 'userCulturalEvent';
      const rentType = siteId === 1 ? 'concert' : 'cultural';

      loading.setLoading(true, '讀取草稿...');

      this.siteId = siteId;
      const formData = await api[apiMethod].get(id);
      this.formData = _.omit(
        { ...formData, acceptTerm: true },
        ['typeStatus1', 'typeStatus2'],
      );
      this.router.push(`/rent/${rentType}`);

      loading.setLoading(false);
    },
    async initForm() {
      this.formOption = {
        concert: await api.getConcertFormOption(),
        cultural: await api.getCulturalFormOption(),
      };
    },
    resetForm() {
      this.formData = {};
      this.formStep = 0;
      this.rentRateTable = {};
    },
    async fetchRentRateTable() {
      const table = this.isConcert
        ? await api.getConcertRentRate({ sessionType: this.formData.activitySessionType, createTime: this.formData.createTime })
        : await api.getCulturalRentRate({ area: this.formData.activityArea });
      if (table === null) {
        this.formData.cMId = 0;
      } else {
        this.formData.cMId = this.isConcert ? table[0].cMId : table.cMId;
      }
      this.rentRateTable = table;
    },
  },
});
